/* 
=================
Table
=====================
*/

.table .search_th.up {
  background-image: url("./images/up_arrow.png");
}

.table .search_th.down {
  background-image: url("./images/down_arrow.png");
}

.table .search_th.default {
  background-image: url("./images/default.png");
}

.table__cell--inactive {
  background-color: #f488bc3c
}

.search_th.up,
.search_th.default,
.search_th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

/* 
=================
Search
=====================
*/

.search {
  width: 95%;
  border: 2px solid #000;
  border-radius: 2em;
  overflow: hidden;
  font-size: 1rem;
  display: flex;
  flex-flow: row nowrap;
}

#search:not(#root > div > table > thead > tr > th)

.search:focus-within {
  box-shadow: 2px 2px 5px #000;
}

.search__button,
.search__input {
  background-color: #fff;
  border: none;
  outline: none;
}

.search__input {
  background-color: transparent;
  width: 85%;
}

.search__button {
  width: 15%;
  font-size: 1.5rem;
  padding-right: 0.5em;
  color: gray;
  display: grid;
  place-content: center;
  background-color: transparent;
}

.search__header {
  display: flex;
  flex-direction: row;
}

.box1 {
}

.box2 {
}

@media (min-width: 576px) {
  .search {
    font-size: 1.5rem;
  }

  .search__button {
    font-size: 2rem;
  }
}

@media (min-width: 800px) {
  .search {
    width: 75%;
    font-size: 2rem;
  }

  .search__button {
    padding-right: 0.5em;
    font-size: 3rem;
  }
}

/* || TABLES - GENERAL */

.table__thead {
  position: sticky;
  top: 0;
  z-index: 1;
}