@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* || VARIABLES */

:root {
  /* COLORS */
  --BGCOLOR: #194e5413;
  --BORDER-COLOR: #FFF;
  --COLOR: #075350;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: hsla(240, 64%, 27%, 0.65);
  --LINK-HOVER: rgba(245, 245, 245, 0.9);
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: #FFF;
  --TABLE-COLOR: #000;
  --INPUT-INVALID: #F00;
}

/* || UTILITY CLASSES */
.errmsg {
  display: inline-block;
  background-color: var(--TABLE-BGCOLOR);
  color: var(--ERROR);
  padding: 0.25em;
  margin-bottom: 0.5em;
}

.errmsg a:any-link {
  color: var(--ERROR);
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

.icon-button {
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: var(--COLOR);
  /*display: grid;*/
  place-content: center;
}

.icon-button:hover,
.icon-button:focus-visible {
  transform: scale(1.2);
  transition-duration: 0.5s;
}

/*
.icon-button[disabled] {
  visibility: hidden;
}
*/

.icon-gear {
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: var(--COLOR);
  /*display: grid;*/
  place-content: center;
}

.faTrashCan {
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: var(--STATUS-OPEN);
  /*display: grid;*/
  place-content: center;
}

/* || GENERAL STYLES */

body {
  font-family: 'Nunito', sans-serif;
  min-height: 100vh;
  background-color: var(--BGCOLOR);
  color: var(--COLOR);
  /*background-image: url('./img/background.jpg');*/
  background-size: cover;
  background-position: top right;
  background-blend-mode: multiply;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root {
  /* React root div */
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

a {
  text-decoration: none;
}

a:any-link {
  color: var(--LINK-COLOR);
}

a:hover,
a:focus-visible {
  color: var(--LINK-HOVER);
}

/* || PAGES */

.public,
.welcome {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.public {
  padding: 1em;
  flex-grow: 1;
}

.public__main,
.login {
  flex-grow: 1;
  border-top: 2px solid var(--BORDER-COLOR);
  border-bottom: 2px solid var(--BORDER-COLOR);
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 40%;
}

.public__addr {
  margin-top: 1em;
}

.welcome a::before {
  content: "➜ "
}

/* || DASH HEADER, CONTAINER & FOOTER */

.dash-header {
  /*position: sticky;
  top: 0;
  z-index: 1;*/
  background-color: var(--BGCOLOR);
  z-index: 1;
  padding: 0;
  /*padding: 0 0.5em;
  border-bottom: 1px solid var(--BORDER-COLOR);*/
}

.dash-header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
/*
.dash-header__container--small {
  max-width: 800px;
}
*/
.dash-header__nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 0.5em;
}

.dash-container {
  padding: 0.75em 0.5em 0.5em;
  flex-grow: 1;
}

.dash-footer {
  font-size: 1rem;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: var(--BGCOLOR);
  /*padding: 0.5em;*/
  border-top: 1px solid var(--BORDER-COLOR);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 1em;
}
.dash-footer p, button{
  margin-bottom: 0;
  padding-bottom: 0;
}

.dash-footer__button {
  font-size: 1rem;
  width: auto;
  height: auto;
}

.otpInput {
  width: 40px;
  height: 40px;
  margin: 5px;
  text-align: center;
  font-size: 1.2em;
  border-radius: 10px;
  border: 2.5px solid #174f43;
}

.alerts-border {
  border: 2px #056a20 solid;
  border-radius: 8px;
  animation: blink 1s;
  animation-delay: 0.5s;
  animation-iteration-count: 3;
  width: 40%;
}

@keyframes blink { 50% { border-color:#ffffff ; }  }

@media screen and (max-width: 400px) {
  h1 {
    font-size: 2rem;
  }

  /*.dash-header__nav {
    gap: 0;
  }*/

  .icon-button,
  .icon-gear,
  .faTrashCan {
    font-size: 1.5rem;
  }
}


@media screen and (min-width: 768px) {

  body {
    font-size: 1.25rem;
  }

  /*.dash-header {
    padding: 0 1em;
  }*/

  .dash-container {
    padding: 1em;
  }

  .table {
    font-size: 1.5rem;
  }

  .table--notes {
    grid-template-columns: repeat(3, 1fr) 2fr repeat(2, 1fr);
  }

  .note__created,
  .note__updated,
  .note__username {
    display: block
  }

  .form__action-buttons {
    position: static;
  }
}

/* //////////////////////////// */

.clever_logo {
  transition: transform .35s; /* Animation */
  width: 40px;
  height: 40px;
}

.clever_logo:hover {
  transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@media screen and (max-width: 992px) {
  .login {
    width: 90%;
  }
}
