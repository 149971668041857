.rcorners2 {
  border-radius: 15px 70px 30px;
  /*background: #8abd9dd0;*/
  padding: 20px;
  width: 210px;
  height: 250px;
  background-color: #D9AFD9;
  background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%);
}

.night {
  background: #62a78272;
}

.day {
  background: #d0d4847e;
}

.weatherTable {
  width: 100%;
  height: 100%;
}
