/* || TABLES - GENERAL */

thead .table__thead,
tbody .tbody_notes,
tr .tr_notes {
  display: contents;
}

table .table--notes{
  font-size: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  color: var(--TABLE-COLOR);
  gap: 0.1em;
}

.table__button {
  padding: 0.25em;
  font-size: 1.5rem;
  color: var(--TABLE-COLOR);
}

.table__thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table__th,
.table__cell {
  background-color: var(--TABLE-BGCOLOR);
  text-align: left;
  border: 1px solid var(--TABLE-COLOR);
  padding: 0.5em;
}

.table__cell:last-child {
  display: grid;
  place-content: center;
}

.table__cell--inactive {
  background-color: var(--INACTIVE);
}

/* ||  NOTE */

.note__created,
.note__updated,
.note__username {
  display: none;
}

.note__status {
  font-weight: bold;
}

.note__status--open {
  color: var(--STATUS-OPEN);
}

.note__status--completed {
  color: var(--STATUS-COMPLETE);
}


/* || FORM - GENERAL */

.form {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75em;
  max-width: 800px;
}

.form__checkbox-container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5em;
}

.form__persist {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5em;
}

.form__title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__input,
.form__submit-button {
  padding: 0.5em;
  border-radius: 15px;
}

.form__input--text {
  min-height: 150px;
}

.form__input--incomplete {
  border: 1px solid var(--INPUT-INVALID);
  outline: 1px solid var(--INPUT-INVALID);
}

.form__checkbox {
  width: 24px;
  height: 24px;
}

.form__select {
  width: 50%;
  padding: 0.25em;
}

.form__action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
  position: absolute;
  right: 0.5em;
}

.form__row {
  display: flex;
  flex-flow: row nowrap;
  gap: 2em;
}

.form__divider {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
}